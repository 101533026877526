import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const InfoBlock = makeShortcode("InfoBlock");
const Box = makeShortcode("Box");
const Aligner = makeShortcode("Aligner");
const Button = makeShortcode("Button");
const Column = makeShortcode("Column");
const Video = makeShortcode("Video");
const Chocolate = makeShortcode("Chocolate");
const Flex = makeShortcode("Flex");
const Icon = makeShortcode("Icon");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "liquid-learning",
      "style": {
        "position": "relative"
      }
    }}>{`Liquid Learning`}<a parentName="h1" {...{
        "href": "#liquid-learning",
        "aria-label": "liquid learning permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <p>{`DeFi is best learned about through ongoing dialogue with your peers in practical places. In order to encourage a participatory approach which is as dynamic as DeFi can be at its best, we keep our content in the ever-growing and changing `}<a parentName="p" {...{
        "href": "https://github.com/kernel-community/library/tree/main/Topic%20-%20DeFi/"
      }}>{`Kernel Library`}</a>{`, to which we encourage you to contribute. `}</p>
    <p>{`To give you a brief taste of what is there, we end up defining DeFi (after discussing what decentralization is and what finance means), as:`}</p>
    <blockquote>
      <p parentName="blockquote">{`A set of systems for communicating reliably what and whom we have faith in, and who is able to settle, pardon, or otherwise forgive our debt. There is no single controller of these systems; they are resilient; and they are replicated in terms of both access and functionality.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`Given that our access to meaning, and the ability to make money representative of our values, has traditionally been regulated by other people who may not have our best interests at heart, this is an interesting topic to study. It does not mean it is necessarily good: that comes down to what you choose to put your faith in and how you choose to express that faith. In other words, what code will you adopt?`}</p>
    </blockquote>
    <p>{`If you don't like definitions and would like to get on with adopting code, we reckon this is a good place to start:`}</p>
    <InfoBlock mdxType="InfoBlock">
      <Box mdxType="Box">
        <p><strong parentName="p">{`Uniswap V3 Development Book`}</strong></p>
        <p><img parentName="p" {...{
            "src": "/images/build/uni.jpg",
            "alt": "Uni V3"
          }}></img></p>
        <Aligner center mdxType="Aligner">
          <Button to="https://uniswapv3book.com/" mdxType="Button">
    Practice
          </Button>
        </Aligner>
      </Box>
    </InfoBlock>
    <h2 {...{
      "id": "wacth--learn",
      "style": {
        "position": "relative"
      }
    }}>{`Wacth & Learn`}<a parentName="h2" {...{
        "href": "#wacth--learn",
        "aria-label": "wacth  learn permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <Column sx={{
      gap: "10%"
    }} mdxType="Column">
      <Box mdxType="Box">
        <p><strong parentName="p">{`DeFi 101 - The Economics of Token Engineering`}</strong></p>
        <Video src="https://www.youtube-nocookie.com/embed/9fPrFh142S8" mdxType="Video" />
      </Box>
      <Box mdxType="Box">
        <p><strong parentName="p">{`DeFi 102.1 - Standards for DeFi protocols`}</strong></p>
        <Video src="https://www.youtube-nocookie.com/embed/wx83CxiKdQk" mdxType="Video" />
      </Box>
      <Box mdxType="Box">
        <p><strong parentName="p">{`DeFi 102.2 - DeFi inspirations from other economic systems`}</strong></p>
        <Video src="https://www.youtube-nocookie.com/embed/L48GwIzjntQ" mdxType="Video" />
      </Box>
      <Box mdxType="Box">
        <p><strong parentName="p">{`DeFi 102.3 - Auctions: a forward looking history`}</strong></p>
        <Video src="https://www.youtube-nocookie.com/embed/dbi4NUX6aSQ" mdxType="Video" />
      </Box>
      <Box mdxType="Box">
        <p><strong parentName="p">{`DeFi 102.4 - Price discovery innovations`}</strong></p>
        <Video src="https://www.youtube-nocookie.com/embed/0cYrSdiJk0U" mdxType="Video" />
      </Box>
      <Box mdxType="Box">
        <p><strong parentName="p">{`DeFi 201.1 - Market Structure & Design`}</strong></p>
        <Video src="https://www.youtube-nocookie.com/embed/vlPIFYfG0FU" mdxType="Video" />
      </Box>
      <Box mdxType="Box">
        <p><strong parentName="p">{`Liquidity Incentives with Curtis Spencer`}</strong></p>
        <Video src="https://www.youtube-nocookie.com/embed/3WkEpAUD4Bw" mdxType="Video" />
      </Box>
      <Box mdxType="Box">
        <p><strong parentName="p">{`Risk, Insurance & Audits with Jack Sanford`}</strong></p>
        <Video src="https://www.youtube-nocookie.com/embed/C7xevKudRZk" mdxType="Video" />
      </Box>
      <Box mdxType="Box">
        <p><strong parentName="p">{`NFTs, Fixed Interest Rate & ADM, but also how to find good people`}</strong></p>
        <Video src="https://www.youtube-nocookie.com/embed/VFa7ca9uX4E" mdxType="Video" />
      </Box>
    </Column>
    <h2 {...{
      "id": "resources",
      "style": {
        "position": "relative"
      }
    }}>{`Resources`}<a parentName="h2" {...{
        "href": "#resources",
        "aria-label": "resources permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <Box sx={{
      backgroundColor: 'primaryMuted',
      borderRadius: '12px',
      padding: '1rem',
      display: 'flex',
      marginBottom: '1rem',
      flexDirection: 'column',
      flexWrap: 'wrap'
    }} mdxType="Box">
      <Chocolate sx={{
        marginBottom: '1.5rem'
      }} mdxType="Chocolate">
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column'
        }} mdxType="Flex">
          <Icon size={"40px"} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`Begin Here`}</strong></p>
          <p>{`The "Topic - DeFi" was started by Danny Gattas, and is intended for all to contribute to and participate in`}</p>
          <Button to="https://github.com/kernel-community/library/" sx={{
            position: 'absolute',
            bottom: '-40px'
          }} mdxType="Button">
    Start
          </Button>
        </Flex>
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column'
        }} mdxType="Flex">
          <Icon size={"40px"} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`Read`}</strong></p>
          <p>{`A Protocol reading list put together by Kernel fellow Brian Chiang which will give you even wider exposure to all the work happening in DeFi.`}</p>
          <Button to="https://github.com/bryanhpchiang/protocol-reading-list" sx={{
            position: 'absolute',
            bottom: '-40px'
          }} mdxType="Button">
    Explore
          </Button>
        </Flex>
      </Chocolate>
    </Box>
    <h3 {...{
      "id": "mev",
      "style": {
        "position": "relative"
      }
    }}>{`MEV`}<a parentName="h3" {...{
        "href": "#mev",
        "aria-label": "mev permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <p>{`There are so many concepts to understand in DeFi that it is difficult to pick out where to start. For those of you serious about building stuff, we feel that understanding more about Miner Extractable Value is a great place to begin because it genuinely is new and different to any other financial environment we have ever created, and so understanding this will help you understand most everything else you need to in order to build constructive and beneficial services in this brave new world.`}</p>
    <Box sx={{
      backgroundColor: 'primaryMuted',
      borderRadius: '12px',
      padding: '1rem',
      display: 'flex',
      marginBottom: '1rem',
      flexDirection: 'column',
      flexWrap: 'wrap'
    }} mdxType="Box">
      <Chocolate sx={{
        marginBottom: '1.5rem'
      }} mdxType="Chocolate">
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column'
        }} mdxType="Flex">
          <Icon size={"40px"} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`The Total Overview`}</strong></p>
          <p>{`A wonderful and extremely detailed overview of everything you need to know about MEV. We recommend starting about halfway down.`}</p>
          <Button to="https://calblockchain.mirror.xyz/c56CHOu-Wow_50qPp2Wlg0rhUvdz1HLbGSUWlB_KX9o" sx={{
            position: 'absolute',
            bottom: '-40px'
          }} mdxType="Button">
    Understand
          </Button>
        </Flex>
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column'
        }} mdxType="Flex">
          <Icon size={"40px"} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`MEV Opportunities`}</strong></p>
          <p>{`A helpful introduction to different opportunities and the tools for finding and leveraging them from Kernel fellow horsefacts.`}</p>
          <Button to="https://horsefacts.notion.site/horsefacts/Intro-to-MEV-opportunities-81d8eb1c24da4cdabb1bc2fc1da67fe8" sx={{
            position: 'absolute',
            bottom: '-40px'
          }} mdxType="Button">
    Start
          </Button>
        </Flex>
        <Flex sx={{
          position: 'relative',
          flexDirection: 'column'
        }} mdxType="Flex">
          <Icon size={"40px"} name="open_in_new_tab" mdxType="Icon" />
          <p><strong parentName="p">{`Flashbots Protect`}</strong></p>
          <p>{`Reading the flashbots docs will help you get up and running with your own work after researching all the above.`}</p>
          <Button to="https://docs.flashbots.net/flashbots-protect/rpc/quick-start/" sx={{
            position: 'absolute',
            bottom: '-40px'
          }} mdxType="Button">
    Start
          </Button>
        </Flex>
      </Chocolate>
    </Box>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      